import Input from "../components/Form/Input";
import SubmitButton from "../components/Form/SubmitButton";
import { useRef } from "react";
import { FunctionComponent } from "react";
import { useState } from "react";
import { XCircleIcon, ExclamationTriangleIcon } from "@heroicons/react/24/outline";
import { CheckCircleIcon } from "@heroicons/react/24/solid";
import usePostFormData from "../hooks/usePostFormData";
import { useNavigate } from "react-router-dom";
import FormState from "../models/FormState";

const digitRegex = new RegExp("[0-9]");
const lowercaseRegex = new RegExp("[a-z]");
const uppercaseRegex = new RegExp("[A-Z]");
const specialCharRegex = new RegExp("[!\"#$%&'()*+,-./:;<=>?@[\\]^_`{|}~]");

const ChangePasswordPage: FunctionComponent<{ message: string }> = ({
  message,
}) => {
  const navigation = useNavigate();
  const postFormData = usePostFormData();

  const [hasDigit, setHasDigit] = useState(false);
  const [hasLowercase, setHasLowercase] = useState(false);
  const [hasUppercase, setHasUppercase] = useState(false);
  const [hasSpecialChar, setHasSpecialChar] = useState(false);
  const [hasAcceptedLength, setHasAcceptedLength] = useState(false);
  const [passwordsTheSame, setPasswordsTheSame] = useState(false);
  const [formState, setFormState] = useState(FormState.Normal);
  const [feedback, setFeedback] = useState("");

  const password1 = useRef<HTMLInputElement>(null);
  const password2 = useRef<HTMLInputElement>(null);

  const buttonDisabled =
    !(
      hasDigit &&
      hasLowercase &&
      hasUppercase &&
      hasSpecialChar &&
      hasAcceptedLength &&
      passwordsTheSame
    ) ||
    formState === FormState.Processing ||
    formState === FormState.Completed;

  function validatePasswordsTheSame() {
    setPasswordsTheSame(password1.current!.value === password2.current!.value);
  }

  function passwordValidator() {
    const password = password1.current!.value;

    setHasDigit(digitRegex.test(password));
    setHasLowercase(lowercaseRegex.test(password));
    setHasUppercase(uppercaseRegex.test(password));
    setHasSpecialChar(specialCharRegex.test(password));
    setHasAcceptedLength(password.length >= 8 && password.length <= 64);
    validatePasswordsTheSame();
  }

  function getFeedbackIcon(isSuccess: boolean) {
    let classNames = "h-6 w-6 mr-1 shrink-0";
    if (isSuccess) {
        return (
            // parent element like span is needed to avoid issues with google translate
            <span>
                <CheckCircleIcon className={classNames} />
            </span>
        );
    } else {
        return (
            // parent element like span is needed to avoid issues with google translate
            <span>
                <XCircleIcon className={classNames} />
            </span>
        );
    }
  }

  async function SubmitHandler(event: React.FormEvent<HTMLFormElement>) {
    event.preventDefault();
    setFormState(FormState.Processing);
    const { status, data } = await postFormData.post("/new-password", [
      {
        name: "password",
        value: password1.current!.value,
      },
    ]);

    if (status === 200) {
      setFormState(FormState.Completed);
      setTimeout(() => {
        navigation("/");
      }, 500);
    } else {
      setFormState(FormState.Normal);
      const errorMessage = data?.error_message
        ? data.error_message
        : "Something went wrong. Please try again later.";
      setFeedback(errorMessage);
    }
  }

  return (
    <>
      <div className="mb-2 flex justify-center">
        <p className="text-sm text-gray-700 font-semibold">{message}</p>
      </div>
      <form onSubmit={SubmitHandler}>
        <Input
          type="password"
          id="password"
          required={true}
          label="Password"
          name="password1"
          autoComplete="off"
          ref={password1}
          autoFocus={true}
          onChange={passwordValidator}
          disabled={
            formState === FormState.Processing ||
            formState === FormState.Completed
          }
        ></Input>

        <Input
          type="password"
          id="password2"
          required={true}
          label="Repeat Password"
          name="password2"
          autoComplete="off"
          ref={password2}
          className="mb-2 mt-2"
          onChange={validatePasswordsTheSame}
          disabled={
            formState === FormState.Processing ||
            formState === FormState.Completed
          }
        ></Input>

        {feedback !== "" && formState === FormState.Normal && (
          <div className="text-red-600 mb-1 flex text-sm">
            <ExclamationTriangleIcon className="h-5 w-5 mr-1"></ExclamationTriangleIcon>
            {feedback}
          </div>
        )}
        <SubmitButton
          text="Change Password"
          isProcessing={formState === FormState.Processing}
          isDisabled={buttonDisabled}
          isCompleted={formState === FormState.Completed}
        />
      </form>
      <div className="mt-5 text-gray-900">
        <p className="font-semibold">Password rules:</p>
        <ul className="list-inside mt-2 space-y-1.5 space-x-1 text-sm">
          <li
            className={`ml-1 flex items-center ${
              hasAcceptedLength ? "text-green-800" : "text-gray-900"
            }`}
          >
            {getFeedbackIcon(hasAcceptedLength)}
            Minimum 8 characters (maximum 64)
          </li>
          <li
            className={`flex items-center ${
              hasDigit ? "text-green-800" : "text-gray-900"
            }`}
          >
            {getFeedbackIcon(hasDigit)}1 number
          </li>
          <li
            className={`flex items-center ${
              hasUppercase ? "text-green-800" : "text-gray-900"
            }`}
          >
            {getFeedbackIcon(hasUppercase)}1 uppercase letter
          </li>
          <li
            className={`flex items-center ${
              hasLowercase ? "text-green-800" : "text-gray-900"
            }`}
          >
            {getFeedbackIcon(hasLowercase)}1 lowercase letter
          </li>
          <li
            className={`flex items-center ${
              hasSpecialChar ? "text-green-800" : "text-gray-900"
            }`}
          >
            {getFeedbackIcon(hasSpecialChar)}
            <div>
              1 special character{" "}
              <p className="font-mono">
                (!"#$%&amp;'()*+,-./:;&lt;=&gt;?@[\]^_`&#123;|&#125;~)
              </p>
            </div>
          </li>
          <li
            className={`flex items-center ${
              passwordsTheSame ? "text-green-800" : "text-gray-900"
            }`}
          >
            {getFeedbackIcon(passwordsTheSame)}
            <div>Passwords must be the same</div>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ChangePasswordPage;
